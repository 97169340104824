const divideIntoLetters = (node) =>
  [...node.childNodes].reduce((result, n) => {
    switch (n.nodeType) {
      case Node.ELEMENT_NODE:
        return divideIntoLetters(n);
      case Node.TEXT_NODE:
        const newElements = n.textContent.split("").map((letter) => {
          const el = document.createElement("span");
          el.innerHTML = letter;
          node.appendChild(el);
          return el;
        });

        // Empty original text
        n.textContent = "";

        return [...result, ...newElements];
    }
  }, []);

const shuffleArray = (arr) =>
  arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector(".scramble");
  if (!el) return;

  const letters = shuffleArray(divideIntoLetters(el));
  letters.forEach((l) => (l.style.opacity = 0));

  let interval = setInterval(() => {
    if (letters.length) {
      letters[0].style.opacity = 1;
      letters[0].style.transition = `opacity ${0.3 + Math.random()}s linear`;
      letters.shift();
    } else {
      clearInterval(interval);
    }
  }, 50);
});
